@import "../../vars";

.BigLink {
  text-align: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: $font-size-icons;

  .big-icon {}

  .icon-text {
    margin-top: $padding-small;
  }

  &:hover {
    .big-icon {
      outline: 3px solid $brand-blue;
      border-color: $brand-blue;
    }
    .icon-text {
      color: $brand-blue;
    }
  }

  @include mobile() {
    padding: $padding-small 0;
  }
}