@import "../../vars";

.DesktopDecor {
  position: fixed;
  width: 100%;
  height: 100vh;
  $top: 80px;
  $spacer: 200px;

  #icon-1 {
    left: 3%;
    top: $top;
  }

  #icon-2 {
    left: 10%;
    top: $top + $spacer;
  }

  #icon-3 {
    left: 6%;
    top: $top + ($spacer * 2);
  }

  #icon-4 {
    right: 6%;
    top: $spacer;
  }

  #icon-5 {
    right: 5%;
    top: $spacer * 1.7;
  }

  #icon-6 {
    right: 30px;
    bottom: 70px;
  }

}