@import "vars";

.Homepage {
  max-width: 830px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-self: center;
  width: 100%;
  padding: $padding-large 0;
  z-index: 1;

  .WindowBlock {
    margin-bottom: $padding-large;
  }
}

.intro {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  $padding: $padding-medium;

  @include mobile() {
    flex-direction: column;
  }

  .intro-pic {
    display: flex;
    flex-grow: 1;
    flex-flow: column wrap;
    padding: $padding;
    font-family: $font-headers;
    font-weight: bold;

    &--pic {
      width: 100%;
      height: auto;
      max-width: 500px;

      @include mobile() {
        max-width: 250px;
      }
    }

    &__name {
      font-family: $font-headers;
      font-size: 1.5em;
      margin: 18px 0 12px;
    }

    &__desc {
      font-family: $font-headers;
      margin-bottom: 6px;
    }
  }

  .intro-text-box {
    display: flex;
    flex-grow: 3;
    flex-flow: column wrap;
    padding: $padding $padding $padding 0;

    @include mobile() {
      padding: $padding;
    }

    p {
      margin-block-start: $padding-small;
      margin-block-end: $padding-small;
    }

    div {
      display: flex;
      flex-flow: column wrap;
      padding: $padding-medium $padding-large;
      background: $white;
      box-shadow: inset 0px 0px 0px 9px #22222287;
    }
  }
}

.message {

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  $padding: $padding-small;

  &_sidebar {
    display: flex;
    flex-grow: 3;
    flex-flow: column wrap;
    min-width: 120px;
    background: $white;
    height: 100%;
    margin: $padding;
    //padding: $padding;

    @include mobile () {
      min-width: 80px;
    }

    &_item{
      border-bottom: $border-small;
      padding: $padding;
      font-family: $font-headers;
      color: $text-body;
      text-decoration: none;
      font-size: $font-size-icons;

      &:last-of-type {
        border-bottom: none;
      }

      &--selected {
        color: $white;
        background: $black;
      }
    }
  }

  &_body {
    background: $white;
    margin: $padding $padding $padding 0;
    padding: $padding;

    &_subject {
      padding-bottom: $padding;
      border-bottom: $border-small;
      font-size: $font-size-icons;
      color: $grey;

      .subjectline {
        font-family: $font-headers;
        color: $text-body;
        margin-left: 2px;
      }

      .date {
        float: right;

        @include mobile () {
          display: none;
        }
      }
    }
    &_email {

      &_attachments {
        padding-top: $padding;
        font-size: $font-size-icons;
        color: $grey;
        border-top: $border-small;

        .links {
          justify-content: flex-start;

          .BigLink {
            flex-grow: 0;
            margin-right: $padding-medium;
          }

          .cover {
            $size: 135px;
            width: $size;
            height: $size;
            margin-right: $padding-medium;
            border: $border-small;
          }
        }
      }
    }
  }
}

.playlists {
  padding: $padding-medium;
  overflow: hidden;

  div {
    margin-bottom: $padding-medium;
  }
}

.video {
  text-align: center;
}