@import "../../vars";

.Secrets2 {

  .listOfSecrets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    .secret {
      min-width: 33%;
      width: 33%;
      font-family: $font-body;
      font-weight: normal;

      @include mobile() {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  //.WindowBlock__body {
  //  padding-bottom: 100px;
  //  overflow: hidden;
  //  height: 100%;
  //}

  .form {
    height: 100%;
  }

  .text-box--small {
    padding-bottom: 15px;
  }

}

button.delete {
  background: red;
  color: #fff;
}

.secret {
  .message_body_email {
    padding: 5px 0;
  }
}

.response {
      display: none;
  }

.explode {
  background-image: url('../../images/explosion.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

    .message {
      visibility: hidden;
      opacity: 0;
    }
}

.exploded {
  .message {
      visibility: hidden;
      opacity: 0;

      .message_body {
      display: none;
      }
    }
  .response {
      display: inline-block;
      color: #fff;
      text-align: center;
      padding: 15px 0;
      width: 100%;

      .fuckthat {
        display: inline-block;
        margin-bottom: 40px;
        text-shadow: -4px 4px #ef3550,
               -8px 8px #f48fb1,
               -12px 12px #7e57c2,
               -16px 16px #2196f3,
               -20px 20px #26c6da,
               -24px 24px #43a047,
               -28px 28px #eeff41,
               -32px 32px #f9a825,
               -36px 36px #ff5722;
      }
      }

      
}

.fuckit {
  color: #fff;
}

.Secrets2__links {
  padding-bottom: 10px;

  a {
    color: #fff;
    margin-bottom: 10px;
    line-height: 2;
  }
}