@import "vars";

$header-height: 5px;

.App {
  text-align: center;
  background: $black;
  min-height: 100vh;
  background-image: url("./pattern.png");
  background-repeat: repeat;
  background-size: 50%;
  background-position: top center;

  @include mobile() {
    background-size: 90%;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: $bg-app-header;
  min-height: $header-height;
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  font-size: 16px;
  color: $text-body;
  font-family: $font-headers;
  font-weight: bold;
  padding: 0 20px;
  z-index: 10;

  .header-item {
    margin-right: 20px;
    align-self: center;
    z-index: 1;
    text-decoration: none;
    color: $black;
  }
}

.App-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 16px;
  color: $text-body;
  font-family: $font-headers;
  font-weight: bold;
  padding-top: $header-height + $padding-small;
}

.text-link {
  padding: $padding-medium;
  color: $brand-primary;
}

.big-icon {
  $size: 100px;
  $size-mobile: 50x;
  background: $white;
  width: $size;
  height: $size;
  border: $border-chunky solid $black;
  border-radius: 100%;
  text-align: center;
  color: $text-body;
  display: flex;

  @include mobile() {
    width: $size-mobile;
    height: $size-mobile;
  }

  img {
    width: 85%;
    display: flex;
    align-self: center;
    margin: 0 auto;
    border-radius: 100%;
  }
}

.icon-text {
  font-family: $font-headers;
  font-weight: bold;
  text-align: center;
  color: $text-body;
  text-decoration: none;
}

.links {
  padding: $padding-medium;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @include mobile() {
    flex-direction: column;
  }
}

.mp3 {
  @include mobile() {
    margin-bottom: $padding-medium;
  }
}

.text-box--large {
  font-family: $font-headers;
  background: $white;
  padding: $padding-medium;
  text-align: center;
  font-weight: bold;
}

.text-box--small {
  font-family: $font-body;
  background: $white;
  padding: $padding-small $padding-small 0 $padding-small;
  text-align: center;
}

.text-box--inline {
  background: transparent;
  padding: 0 $padding-small $padding-small $padding-small;
}



.footer {
  color: $text-headers;
  font-size: 80%;

  p {
    margin: 0 auto;
  }
}

.countdown {
  margin-left: auto;
  padding-right: $padding-medium;
}

.secrets {
  text-align: center;
  margin: 0 auto;
}



//MARQUEE
.marquee {
  $width: 90vw;
  width: $width;
  height: 50px;
  overflow: hidden;
  position: absolute;
  left: 200px;
  margin: 0 auto;
  z-index: 0;
  //background: #fefefe;
  //color: #333;
  //border: 1px solid #4a4a4a;

  @include mobile () {
    display: none;
  }

  p, a {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 50px;
    text-align: center;
    -moz-transform: translateX($width);
    -webkit-transform: translateX($width);
    transform: translateX($width);
    -moz-animation: scroll-left 0s linear infinite;
    -webkit-animation: scroll-left 0s linear infinite;
    animation: scroll-left 20s linear infinite;
  }

  @-moz-keyframes scroll-left {
    0% {
      -moz-transform: translateX($width);
    }
    100% {
      -moz-transform: translateX(-$width);
    }
  }

  @-webkit-keyframes scroll-left {
    0% {
      -webkit-transform: translateX($width);
    }
    100% {
      -webkit-transform: translateX(-$width);
    }
  }

  @keyframes scroll-left {
    0% {
      -moz-transform: translateX($width);
      -webkit-transform: translateX($width);
      transform: translateX($width);
    }
    100% {
      -moz-transform: translateX(-$width);
      -webkit-transform: translateX(-$width);
      transform: translateX(-$width);
    }
  }
}